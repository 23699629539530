<template>
  <div class="error-tip">
    <div class="error-tip__icon-area">
      <van-icon name="warning" color="#fa5151" size="68"/>
    </div>

    <div class="error-tip__text-area">
      <h2 class="error-tip__title">{{title}}</h2>
      <p class="error-tip__desc">{{message}}</p>
    </div>

    <div class="error-tip__action-area" v-if="showBackhomeButton">
      <van-button  type="primary" to="/" replace size="small" style="width: 144px;">回到首页</van-button>
    </div>
  </div>
</template>

<script>
import { Empty } from 'vant';
export default {
  name: 'ErrorTip',
  components: {
    Empty
  },
  computed: {
    showBackhomeButton() {
      return this.state == '1';
    }
  },
  created() {
    this.title = this.$route.meta.title;
    this.state = this.$route.meta.state;
    this.message = this.$route.meta.errorMsg;
  },
}
</script>

<style lang="scss" scoped>
  .error-tip {
    text-align: center;
    padding: 64px 16px;
    line-height: 1.4;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .error-tip__icon-area {
    margin-bottom: 32px;
  }

  .error-tip__text-area {
    flex: 1;
  }
  .error-tip__title {
    font-size: 22px;
    font-weight: 700;
    color: rgba(0,0,0,.9);
  }

  .error-tip__desc {
    font-size: 16px;
    color: rgba(0,0,0,.9);
  }
</style>